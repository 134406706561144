import $ from 'jquery';

import { initNotify } from './angle/modules/elements/notify';
import initFormsDemo from "./angle/modules/forms/forms";
import initSelect2 from "./angle/modules/forms/select2";
import initParsleyForPages from "./angle/modules/pages/pages";

$(function() {
  initFormsDemo();
  initSelect2();
  initNotify();
  initParsleyForPages();
});