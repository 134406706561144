// Select2
// -----------------------------------

import $ from 'jquery';
// Select2
import 'select2';
// import 'select2/dist/css/select2.min.css';
// import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css';


function initSelect2() {
    var placeholder;
    let placeholder_brand;
    let placeholder_model;
    let placeholder_version;
    let placeholder_fuel_type;
    let placeholder_nr_of_doors;
    let placeholder_gearbox_type;
    let placeholder_transimssion_type;
    let placeholder_color_type;
    let placeholder_body_type;
    let placeholder_year;
    if (document.getElementsByClassName("language-selector")[0].textContent === "Selectată") {
        placeholder = 'Selectează o mașină'
        placeholder_brand = 'Selectează un brand'
        placeholder_model = 'Selectează un model'
        placeholder_version = 'Selectează o versiune'
        placeholder_fuel_type = 'Selectează tipul de combustibil'
        placeholder_nr_of_doors = 'Selectează nr. de uși'
        placeholder_gearbox_type = 'Selectează cutia de viteze'
        placeholder_transimssion_type = 'Selectează transmisia'
        placeholder_color_type = 'Selectează culoarea'
        placeholder_body_type = 'Selectează tipul caroseriei'
        placeholder_year = 'Selectează anul fabricației'
    } else {
        placeholder = 'Select a car'
        placeholder_brand = 'Select a brand'
        placeholder_model = 'Select a model'
        placeholder_version = 'Select a version'
        placeholder_fuel_type = 'Select fuel type'
        placeholder_nr_of_doors = 'Select nr. of doors'
        placeholder_gearbox_type = 'Select gearbox'
        placeholder_transimssion_type = 'Select transmission'
        placeholder_color_type = 'Select color'
        placeholder_body_type = 'Select body type'
        placeholder_year = 'Select production year'
    }

    if (!$.fn.select2) return;

    // Select 2

    $('#select2-1').select2({
        theme: 'bootstrap4'
    });
    $('#select2-2').select2({
        theme: 'bootstrap4'
    });
    $('#select2-3').select2({
        placeholder: placeholder + String.fromCharCode(42) + String.fromCharCode(42),
        allowClear: true,
        theme: 'bootstrap4'
    });
    $('#select_car_brand').select2({
        placeholder: placeholder_brand,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_car_model').select2({
        placeholder: placeholder_model,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_car_version').select2({
        placeholder: placeholder_version,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_car_fuel_type').select2({
        placeholder: placeholder_fuel_type,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_nr_of_doors').select2({
        placeholder: placeholder_nr_of_doors,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_gearbox_type').select2({
        placeholder: placeholder_gearbox_type,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_transmission_type').select2({
        placeholder: placeholder_transimssion_type,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_year').select2({
        placeholder: placeholder_year,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_color_type').select2({
        placeholder: placeholder_color_type,
        allowClear: true,
        theme: 'bootstrap4'
    });

    $('#select_body_type').select2({
        placeholder: placeholder_body_type,
        allowClear: true,
        theme: 'bootstrap4',
        templateResult: formatOption
    });

}

function formatOption(option) {
    var body_type_descriptions = {};
    body_type_descriptions["small_car"] = "Fiat 500, Mini Cooper, Smart, VW Up, VW Beetle, VW Lupo";
    body_type_descriptions["city_car"] = "Ford Fiesta, Opel Corsa, VW Polo, Renault Clio, Seat Ibiza";
    body_type_descriptions["hatchback"] = "VW Golf, Seat Leon, Ford Focus, Toyota Auris, Opel Astra";
    body_type_descriptions["sedan"] = "VW Passat, Skoda Octavia, Renault Symbol, Opel Insignia, Audi A4";
    body_type_descriptions["combi"] = "Skoda Octavia break, Audi A4 Avant, BMW 5 Touring";
    body_type_descriptions["monovolum"] = "Ford C-max, Opel Zafira, Citroen C4 Picasso, Renault Scénic";
    body_type_descriptions["suv"] = "VW Touareg, Volvo XC 60, Toyota RAV4, Kia Sportage, BMW X5";
    body_type_descriptions["cabrio"] = "BMW 3 series, VW Eos, Audi Cabriolet, Porsche Boxster";
    body_type_descriptions["coupe"] = "Audi Coupe, Ford Mustang, Mercedes E Coupé";
    // var $option = $(
    //   '<div><strong>' + option.text + '</strong></div>' +
    //   '<div style="background-color: #e4e7eb; color: white">' + body_type_descriptions[option.id] + '</div>'
    // );
    var $option = $(
     "<span class'custom-option'=\"\">" +
      "<img class=\"custom-option--icon\" style=\"float: right;\" src=\"/assets/car_body_types/"+ option.id +".png\">" +
      "<span class=\"custom-option--text\">"+ option.text +"</span>" +
      "<em class=\"custom-option--hint\"> (ex. " + body_type_descriptions[option.id] + " )</em>" +
      "</span>"
    );
    return $option;
}

export default initSelect2