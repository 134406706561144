/**
 * Used for user pages
 * Login and Register
 */

// Parsley (validation)
import 'parsleyjs/dist/parsley.min.js';
import 'parsleyjs/src/extra/validator/comparison';
import 'parsleyjs/dist/i18n/en';
import 'parsleyjs/dist/i18n/en.extra';
import 'parsleyjs/dist/i18n/ro';
import 'parsleyjs/dist/i18n/ro.extra';
//this is needed because there is an error in the RO translation for this string. This string only. check the dist from above
Parsley.addMessages('ro', {
  length:         "Trebuie să scrii cel puțin %s și cel mult %s caractere.",
});

Parsley.setLocale('ro');

function initParsleyForPages() {

    // Parsley options setup for bootstrap validation classes
    var parsleyOptions = {
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        classHandler: function(ParsleyField) {
            var el = ParsleyField.$element.parents('.form-group').find('input');
            if (!el.length) // support custom checkbox
                el = ParsleyField.$element.parents('.c-checkbox').find('label');
            return el;
        },
        errorsContainer: function(ParsleyField) {
            return ParsleyField.$element.parents('.form-group');
        },
        errorsWrapper: '<div class="text-help">',
        errorTemplate: '<div></div>'
    };

    // Login form validation with Parsley
    var loginForm = $("#loginForm");
    if (loginForm.length)
        loginForm.parsley(parsleyOptions);

    // Register form validation with Parsley
    var registerForm = $("#registerForm");
    if (registerForm.length)
        registerForm.parsley(parsleyOptions);

  var registerForm = $("#new_user");
  if (registerForm.length)
    registerForm.parsley(parsleyOptions);

}

export default initParsleyForPages;